import React from "react";
import Layout from "../../areas/main/components/Layout";
import NavOne from "../../areas/main/components/NavOne";
import Footer from "../../areas/main/components/Footer";
import Banner from "../../areas/main/components/Banner";
import Features from "../../areas/main/components/Features";
import FeaturesIntro from "../../areas/main/components/FeaturesIntro";
import AnswersSection from "../../areas/main/components/AnswersSectionRu";
import WaiterAppSection from "../../areas/main/components/WaiterAppSection";
import KitchenAppSection from "../../areas/main/components/KitchenAppSection";
import Pricing from "../../areas/main/components/Pricing";
import Texts, {switchLang } from "../../utils/lang-utils";

const HomePage = () =>  {
    switchLang("ru");
    return <Layout pageTitle={Texts.siteTitle} language="ru">
      <NavOne />
      <Banner />
      <AnswersSection />
      <FeaturesIntro />
      <WaiterAppSection/>
      <KitchenAppSection />
      <Features />
      <Pricing />
      <Footer />
    </Layout>
};

export default HomePage;
