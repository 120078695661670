import React from "react";
import mocImage from "../assets/images/mocs/people.png";
import Texts from "../../../utils/lang-utils";
import urls from "../../../areas/main/assets/urls";

const AnswersSection = () => {
  return (
    <section className="cta-one">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="cta-one__content">
              <div className="block-title text-center">
                <h2 className="block-title__title">
                  {Texts.ExplanationTitle}
                </h2>
                <h3>
                  {Texts.ExplanationSubtitle}
                </h3>
              </div>
            </div>

          </div>

        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <div className="block-title text-left">
                <h3>
                  {Texts.ExplanationDescriptionIntro}
                </h3>
              </div>
              <div className="cta-two__text">
                <ul class="list-unstyled"> <li> <i class="fa fa-check"></i> <p>Оптимизация работы персонала и экономия времени посетителей</p></li><li> <i class="fa fa-check"></i> <p>Простой и привычный интерфейс в виде чата</p></li><li> <i class="fa fa-check"></i> <p>Статусы выполнения заказа</p></li><li> <i class="fa fa-check"></i> <p>Возможность позвать официанта</p></li><li> <i class="fa fa-check"></i> <p>Лёгкая и быстрая настройка</p></li></ul>
              </div>
              <a href={urls.onboarding} className="thm-btn">
                <span>{Texts.GetStarted}</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={mocImage} alt="alter text" style={{
              marginTop: 40,
              borderRadius: 20,
              boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%'
            }} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AnswersSection;
